import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectCurrentUser } from "../auth/authSlice"
import {
  Review,
  ReviewVideo,
  ReviewImage,
} from "../../models/base/review.interface"
import {
  formatNumber,
  toRatingText,
  toStringFromDateTime,
} from "../../utils/stringUtils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  useDeleteReviewMutation,
  useDeleteProductReviewMutation,
  useSendLikeMutation,
} from "../../app/services/base/reviews"
import { addToast } from "../toasts/toastsSlice"
import checkOrange from "../../assets/icons/check-orange.svg"
import reviewCert from "../../assets/icons/review-cert.svg"
import { Shop } from "../../models/base/shop.interface"
import { getVideoThumbnail, parseReviewVideo } from "../../utils/videoUtils"
import { tryToParse } from "../../utils/objectsUtils"
import SocialShareOld from "../social_share_old/SocialShareOld"
import { getReviewImageUrl, isReportable } from "../../utils/reviewUtils"
import { setReportModal } from "../modals/modalsSlice"
import { Fancybox } from "../fancybox/Fancybox"
import overlayIcon from "../../assets/icons/image_overlay_icon.svg"
import imagePlaceholder from "../../assets/images/thumb_placeholder.webp"

interface ReviewItemProps {
  review: Review
  shop: Shop
  activateActions: boolean
}

export const ReviewItemOld: React.FC<ReviewItemProps> = ({
  review,
  shop,
  activateActions,
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { userId, userName } = useParams()
  const user = useAppSelector(selectCurrentUser)
  const [deleteReview, { error }] = useDeleteReviewMutation()
  const [deleteProductReview] = useDeleteProductReviewMutation()
  const [reviewImages, setReviewImages] = useState<ReviewImage>()
  const [nReviewImages, setNReviewImages] = useState<number>(0)
  const [reviewVideo, setReviewVideo] = useState<ReviewVideo>()
  const [detailsRating, setDetailsRating] = useState<any[]>()
  const [sendLike] = useSendLikeMutation()
  const [isLiked, setIsLiked] = useState<boolean | undefined>(review.liked)

  const [thumbnailUrl, setThumbnailUrl] = useState("")

  let report = user
    ? review.reports?.find((r) => r.consumer_id === user?.id)
    : undefined

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = reviewVideo
          ? await getVideoThumbnail(reviewVideo.videoId, reviewVideo.url)
          : ""
        setThumbnailUrl(url)
      } catch (error) {
        console.error("Error fetching video thumbnail:", error)
      }
    }

    fetchData()
  }, [reviewVideo])

  useEffect(() => {
    const parseData = async () => {
      if (review && review.images) {
        const parsedData = await tryToParse(review.images)
        setReviewImages(parsedData)
        setNReviewImages(parsedData.length)
      }

      if (review && review.video_url) {
        const parsedData = await parseReviewVideo(review.video_url)
        setReviewVideo(parsedData)
      }

      if (review && review.details_rating) {
        const parsedData = await tryToParse(review.details_rating)
        setDetailsRating(parsedData)
      }
    }

    if (review) {
      parseData()
    }
  }, [review])

  async function handleLike() {
    if (!isLiked) {
      try {
        const response = await sendLike({
          review_id: review.id,
        }).unwrap()
        if (!response.success) throw new Error()
      } catch (error) {
        dispatch(
          addToast({
            message: "Oops! Qualcosa è andato storto.",
            type: "danger",
          }),
        )
      }
    }
    setIsLiked(!isLiked)
  }

  async function handleDelete() {
    if (confirm("Sei sicuro di voler eliminare la recensione?")) {
      try {
        const response =
          review.is_prod_review == 1
            ? await deleteProductReview(review.id).unwrap()
            : await deleteReview(review.id).unwrap()
        if (!response.success) throw new Error()

        navigate("/areariservata/reviews-delete-ok")
        setTimeout(() => {
          navigate("/areariservata/reviews")
        }, 5000)
      } catch (err) {
        dispatch(
          addToast({
            message: "Ops! Impossibile eliminare la recensione al momento.",
            type: "danger",
          }),
        )
      }
    }
  }

  // if (!user) {
  //   return <> </>
  // }

  return (
    <div
      className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
      style={userId && userName ? { position: "relative" } : {}}
    >
      <div className="review-item">
        <div className="social-share">
          <SocialShareOld
            title={review.title}
            url={`${import.meta.env.VITE_B2C_BASE_URL}it/reviews/${review?.id}`}
            id={review.id}
          />
        </div>
        {isReportable(review, user?.id) && (
          <div className="flag-segnala-utente">
            <a
              onClick={() =>
                dispatch(setReportModal({ review: review, eshop: shop }))
              }
            >
              <FontAwesomeIcon icon={["fas", "flag"]} className="mr-2" />
              Segnala
            </a>
          </div>
        )}
        <div className="review-body">
          <h2 className="eshop-name">
            <Link to={review.eshop_id ? `/eshop/${shop.domain}` : "#"}>
              {shop.domain}
              {shop.status == 1 && <img src={checkOrange} />}
            </Link>
          </h2>
          <div className="review-rating">
            <div className="general-rating">
              <span className="valutazione-media">
                {formatNumber(Number(review.overall_rating), 1)}
              </span>
              <span className="valutazione-complessiva">
                {toRatingText(Number(review.overall_rating))}
              </span>
            </div>
          </div>
          {report && (
            <div
              style={{
                color: "white",
                backgroundColor: "#eb6717",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              {report.status == 0 || report.status == 3 ? (
                <p>
                  Abbiamo ricevuto la tua segnalazione il{" "}
                  <b>{toStringFromDateTime(report.insert_date)}</b> e il nostro
                  staff la sta valutando.
                </p>
              ) : report.status == 1 ? (
                <p>
                  Abbiamo ricevuto la tua segnalazione e abbiamo ritenuto che la
                  recensione sia da ritenersi conforme alle nostre policy,
                  pertanto continuerà ad essere visibile su eShoppingAdvisor.
                </p>
              ) : (
                <p>
                  Abbiamo ricevuto la tua segnalazione e abbiamo ritenuto che la
                  recensione non sia da ritenersi conforme alle nostre policy,
                  pertanto è stata sospesa e non sarà più visibile su
                  eShoppingAdvisor.
                </p>
              )}
              <p>
                <b>Motivazione:</b>
                <br />
                {report.reason}
              </p>
            </div>
          )}

          {review.is_prod_review == 0 ? (
            <div className="review-title">
              <h2 className="title">
                <Link to={`/reviews/${review.id}`}>{review.title}</Link>{" "}
                <span className="insert-date">
                  {toStringFromDateTime(review.insert_date)}
                </span>
              </h2>
            </div>
          ) : (
            <div className="rev-prod-desc clearfix">
              {review.prod_review?.img_prod && (
                <div className="rev-prod-img">
                  <img
                    src={review.prod_review.img_prod}
                    onError={(e) => (e.currentTarget.src = imagePlaceholder)}
                  />
                </div>
              )}
              <div className="rev-prod-name">
                {review.title}{" "}
                <span className="insert-date">
                  {toStringFromDateTime(review.insert_date)}
                </span>
              </div>
            </div>
          )}
          <div
            className={`review-text ${
              review.is_prod_review == 1 ? "prod-text" : ""
            } break-all`}
          >
            {review.description &&
            (!user || (user && userId && user.id.toString() != userId)) ? (
              <>
                {review.description.substring(0, 49)}
                {review.description.length > 50 && (
                  <>
                    ...
                    <Link
                      to={`/reviews/${review.id}`}
                      className="read-more-review"
                    >
                      Leggi tutto
                    </Link>
                  </>
                )}
              </>
            ) : (
              review.description
            )}
          </div>
          {reviewImages && Object.keys(reviewImages).length > 0 && (
            <Fancybox
              options={{
                Carousel: {
                  infinite: false,
                },
                Toolbar: {
                  display: {
                    left: [],
                    middle: [],
                    right: ["slideshow", "fullscreen", "thumbs", "close"],
                  },
                },
              }}
            >
              <div className="review-gallery-img">
                {reviewImages &&
                  Object.entries(reviewImages).map(([key, value]) => (
                    <div className="review-gallery-item" key={key}>
                      <a
                        data-src={getReviewImageUrl(
                          value.name,
                          review.id,
                          review.is_prod_review,
                        )}
                        data-fancybox="review-gallery"
                      >
                        <img
                          src={getReviewImageUrl(
                            value.name,
                            review.id,
                            review.is_prod_review,
                          )}
                          className="img-fluid"
                          width="90"
                          height="90"
                          loading="lazy"
                        />
                        <div className="overlay-middle">
                          <img src={overlayIcon} loading="lazy" />
                          <span className="font-12 white-color">
                            Visualizza
                          </span>
                        </div>
                      </a>
                    </div>
                  ))}
              </div>
            </Fancybox>
          )}

          {reviewVideo && (
            <div className="video-review-container">
              <div className="d-flex flex-row flex-wrap mb-3">
                <Fancybox
                  options={{
                    Carousel: {
                      infinite: false,
                    },
                    Toolbar: {
                      display: {
                        left: [],
                        middle: [],
                        right: ["slideshow", "fullscreen", "thumbs", "close"],
                      },
                    },
                  }}
                >
                  <a
                    data-src={reviewVideo.url}
                    data-fancybox="review-gallery-video"
                  >
                    <img src={thumbnailUrl} className="height-150" />
                  </a>
                </Fancybox>
              </div>
            </div>
          )}

          <div className="review-detailed-rating">
            {review.is_prod_review == 0 &&
              review.details_rating &&
              detailsRating && (
                <>
                  <ul className={`detailed-rating`}>
                    {Object.entries(detailsRating)
                      .slice(0, 3)
                      .map(([key, value]) => (
                        <li key={key}>
                          <span className="value">
                            {formatNumber(value, 1)}
                          </span>{" "}
                          {key.replace(/\\\//g, "/")}
                        </li>
                      ))}
                  </ul>
                  <ul className={`detailed-rating`}>
                    {Object.entries(detailsRating)
                      .slice(3)
                      .map(
                        ([key, value]) =>
                          value > 0 && (
                            <li key={key}>
                              <span className="value">
                                {formatNumber(value, 1)}
                              </span>{" "}
                              {key.replace(/\\\//g, "/")}
                            </li>
                          ),
                      )}
                  </ul>
                </>
              )}
            {review.certified == 2 && (
              <div
                className={`review-cert ${
                  review.is_prod_review == 1 ? "prod-cert" : ""
                }`}
                title="s_recensione_cert_i"
              >
                {/* <img src={reviewCert} width={65} /> */}
                <span>
                  <FontAwesomeIcon icon={["fas", "check"]} />
                </span>{" "}
                Verificata
              </div>
            )}
            <div style={{ clear: "both" }}></div>
            {/*review.insert_date && !userId && !userName && 
              <span className="dataAcquisto">
                {" "}
                Data di acquisto: {toStringFromDateTime(
                  review.insert_date,
                )}{" "}
              </span>
                )*/}
          </div>

          {review.certified == 2 ||
            (review.replies && (
              <>
                {review.replies.map(({ reply, reply_date, reply_private }) => (
                  <div className="review-bottom">
                    <div className="review-reply">
                      <h3 className="reply-title">Risposta di {shop.domain}</h3>
                      {reply}
                    </div>
                  </div>
                ))}
              </>
            ))}
        </div>
        {userId && userName && (
          <div className="review-footer" style={{ borderTop: "none" }}>
            <div className="review-reaction">
              <span className="reply-label">Ti è stata utile?</span>
              <a onClick={handleLike} className="utile">
                {isLiked ? (
                  <FontAwesomeIcon icon={["fas", "thumbs-up"]} />
                ) : (
                  <FontAwesomeIcon icon={["far", "thumbs-up"]} />
                )}
              </a>
            </div>
            <div className="review-do-reply">
              {review.replies?.length == 0 && (
                <>
                  Sei {shop.domain} e vuoi rispondere?{" "}
                  <Link
                    to={`https://business.eshoppingadvisor.com/it/reviews_eshop/${shop.id}`}
                    target="_blank"
                  >
                    Clicca qui
                  </Link>
                </>
              )}
            </div>
            <div style={{ clear: "both" }}></div>
          </div>
        )}
      </div>
      {activateActions && (
        <div className="review-actions">
          <form className="" id="review-form">
            <Link
              className="btn btn-update pull-leftc btnbk "
              to={`/areariservata/${
                review.is_prod_review == 1 ? "product-reviews" : "reviews"
              }`}
            >
              <FontAwesomeIcon icon={["fas", "arrow-left"]} /> Torna alle
              recensioni
            </Link>
            <div className="float-right review-right-actions mt-3 mt-lg-0">
              <button
                type="button"
                name="delete"
                id="btn-review-ds"
                className="btn btn-danger mx-1"
                onClick={handleDelete}
              >
                X Elimina recensione
              </button>

              {(!reviewImages || nReviewImages < 5 || !review.video_url) && (
                <Link
                  className="btn btn-update mod-btn mx-1"
                  to={`/areariservata/${
                    review.is_prod_review
                      ? "modifica-media-recensioni-prodotto"
                      : "modifica-media-recensioni"
                  }/${review.id}`}
                >
                  Carica foto {!review.video_url && " e video"}
                </Link>
              )}

              {review.is_prod_review == 0 ? (
                <Link
                  className="btn btn-update mod-btn mx-1"
                  to={`/areariservata/reviews-modify/${review.id}?ac=update`}
                >
                  Modifica
                </Link>
              ) : (
                <Link
                  className="btn btn-update mod-btn mx-1"
                  to={`/areariservata/product-reviews-modify/${review.id}?ac=update`}
                >
                  Modifica
                </Link>
              )}
            </div>
          </form>
          <div style={{ clear: "both" }}></div>
        </div>
      )}
    </div>
  )
}

export default ReviewItemOld
