import React, { useState } from "react"
import { Shop } from "../../../../models/base/shop.interface"
import { Tab } from "react-bootstrap"
import { ReviewContainer } from "../../reviews/ReviewContainer"
import { formatNumber } from "../../../../utils/stringUtils"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  calcTotalReviews,
  isPlanWithBanners,
} from "../../../../utils/shopUtils"
import { useGetSearchReviewsQuery } from "../../../../app/services/esaScraper/reviews"
import { ReviveBanner } from "../../../revive_banner/ReviveBanner"
import { EsaScraperShop } from "../../../../models/esaScraper/esaScraperShop.interface"
import { EShopFilters } from "../../filters/EShopFilters"
import { useAppSelector } from "../../../../app/hooks"
import { selectProgressRatings } from "../../rating_progress_bar/ratingProgressBarSlice"
import { selectCurrentUser } from "../../../auth/authSlice"

interface ReviewsTabContentProps {
  shop: Shop
  isProductTab: boolean
  scraperShop?: EsaScraperShop
}

export const ReviewsTabContent: React.FC<ReviewsTabContentProps> = ({
  shop,
  isProductTab,
  scraperShop,
}) => {
  const user = useAppSelector(selectCurrentUser)
  const selectedProgressRatings = useAppSelector(selectProgressRatings)

  const [filtersFormData, setFiltersFormData] = useState<{
    searchQuery: string | undefined
    orderBy: { label: string; value: string }
  }>({
    searchQuery: undefined,
    orderBy: { label: "Più recenti", value: "publication_datetime-desc" },
  })

  const [searchAfter, setSearchAfter] = useState<number[] | undefined>(
    undefined,
  )

  let pageSize = 10
  // if "preRender" in path and "pageSize" param is present then set pageSize to param value
  if (window.location.pathname.includes("preRender")) {
    const urlParams = new URLSearchParams(window.location.search)
    const pageSizeParam = urlParams.get("pageSize")
    if (pageSizeParam) {
      pageSize = parseInt(pageSizeParam)
    }
  }

  const { data: reviews } = useGetSearchReviewsQuery({
    esaDomainId: shop.id,
    query_string: filtersFormData.searchQuery,
    filters: {
      enrichData: true,
      size: pageSize,
      orderBy: filtersFormData.orderBy.value,
      ratings: selectedProgressRatings,
      esa_user_id: user ? user.id : undefined,
      not_imported: ["pro", "company"].includes(shop.plan)
        ? (false as boolean)
        : (true as boolean),
    },
    review_prod: isProductTab,
    searchAfter,
  })

  function handleOrderChange(order: { label: string; value: string }) {
    setFiltersFormData({ ...filtersFormData, orderBy: order })
    setSearchAfter(undefined)
  }

  function handleSearchQueryChange(searchQuery?: string) {
    setFiltersFormData({ ...filtersFormData, searchQuery })
    setSearchAfter(undefined)
  }

  const totalReviews = calcTotalReviews(shop)

  const percVerified =
    scraperShop?.reviews_cert && scraperShop.reviews
      ? Number(
          ((scraperShop?.reviews_cert * 100) / scraperShop?.reviews).toFixed(1),
        )
      : 0

  return (
    <Tab.Pane
      eventKey={isProductTab ? "productsReviews" : "shopReviews"}
      aria-labelledby={
        isProductTab ? "Recensioni prodotti" : "Recensioni eCommerce"
      }
    >
      {isProductTab ? (
        <p className="font-lighter dark-color font-14 m-0 mb-3 mb-lg-4">
          Valuta la qualità dei prodotti venduti su {shop.domain} in base alle{" "}
          {reviews?.hits.total.value} recensioni dei clienti che hanno
          acquistato su questo negozio online.
        </p>
      ) : (
        <p className="font-lighter dark-color font-14 m-0 mb-3 mb-lg-4">
          Leggi le opinioni, i suggerimenti e le esperienze di acquisto dei
          clienti. Il sito è stato valutato
          <span className="font-semi-bolder simple-orange-txt">
            {" "}
            {formatNumber(shop.rating ? shop.rating : 0, 1)}{" "}
          </span>
          stelle, e ha ottenuto
          <span className="font-semi-bolder simple-blue-txt">
            {" "}
            {totalReviews}{" "}
          </span>
          {totalReviews == 1 ? "recensione" : "recensioni"}
          {/* TODO - Percentuale verificate */}
          {percVerified > 0 ? (
            <>
              {" "}
              di cui il{" "}
              <span className="font-semi-bolder simple-blue-txt">
                {formatNumber(percVerified, 1)}%
              </span>{" "}
              verificate.
            </>
          ) : percVerified == 0 ? (
            <span className="font-semi-bolder dark-color">
              (Nessuna recensione verificata su eShoppingAdvisor)
            </span>
          ) : (
            <></>
          )}
        </p>
      )}
      {reviews && reviews.hits.hits.length > 0 && (
        <EShopFilters
          shop={shop}
          enableOrderBy
          orderBy={filtersFormData.orderBy}
          handleOrderChange={handleOrderChange}
          enableTags
          searchQuery={filtersFormData.searchQuery}
          handleSearchQueryChange={handleSearchQueryChange}
          isProductTab={isProductTab}
        />
      )}
      <div
        id={isProductTab ? "eshopListProd" : "eshopList"}
        className="list-view"
      >
        <div className="summary"></div>
        <div
          className={
            reviews && reviews.hits.hits.length > 0
              ? "list-group list-group-flush"
              : ""
          }
        >
          {reviews && reviews.hits.hits.length > 0 ? (
            reviews.hits.hits.map((review, index) => {
              return (
                <React.Fragment key={review._source.review.id}>
                  {(isPlanWithBanners(shop.plan) &&
                    (index == 4 || index == 9)) ||
                    (reviews.hits.hits.length < 5 &&
                      reviews.hits.hits.length - 1 == index && (
                        <>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-3 d-none d-none d-md-block">
                            <ReviveBanner zoneId="20" shop={scraperShop} />
                          </div>

                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-md-none text-center">
                            <ReviveBanner zoneId="22" shop={scraperShop} />
                          </div>
                        </>
                      ))}
                  <ReviewContainer
                    shop={shop}
                    review={review._source.review}
                    simpleUser={review._source.user}
                  />
                </React.Fragment>
              )
            })
          ) : (
            <div
              className="alert fade-orange-background d-flex align-items-center p-5 justify-content-center"
              role="alert"
            >
              <div className="row w-100 px-0 px-lg-3">
                <div className="col-12 text-center mb-3">
                  <FontAwesomeIcon
                    icon={["fas", "exclamation-triangle"]}
                    className="orange-color font-66"
                  />
                </div>
                <div className="col-12 text-center dark-color font-14 mb-3 px-0 px-lg-5">
                  Non ci sono recensioni per questo eCommerce ma puoi sempre
                  cominciare a scriverla tu, che aspetti?!
                </div>
                <div className="col-12 text-center dark-color font-14 d-flex justify-content-center">
                  <Link
                    to={`/invia-recensione?e=${shop.id}`}
                    className="btn btn-animation btn-action btn-gradient-orange btn-ecommerce-details py-2 d-flex align-items-center justify-content-center"
                    style={{ width: "238px" }}
                  >
                    Scrivi una recensione
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
        {reviews?.has_search_after && (
          <div className="col-12 text-center mt-5">
            <button
              className="btn btn-animation btn-action btn-gradient-orange fw-semibold height-50 px-4"
              onClick={() =>
                setSearchAfter(
                  reviews?.hits.hits[reviews?.hits.hits.length - 1].sort
                    ? reviews?.hits.hits[reviews?.hits.hits.length - 1].sort!
                    : [],
                )
              }
            >
              <span className="d-none d-md-block">
                {" "}
                Leggi altre recensioni{" "}
              </span>
              <span className="d-block d-md-none"> Leggi altre </span>
            </button>
          </div>
        )}
      </div>
    </Tab.Pane>
  )
}
