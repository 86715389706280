import React, { useState, useEffect } from "react"
import { useNavigate, useSearchParams, useParams } from "react-router-dom"
import { Carousel, Form } from "react-bootstrap"
import { StepIndicators } from "../../features/send_review/StepIndicators"
import { StepOne } from "../../features/send_review/StepOne"
import { StepThree } from "../../features/send_review/StepThree"
import useWindowSize from "../../utils/useWindowSize"
import { useGetShopByIdQuery } from "../../app/services/base/shops"
import { StepTwo } from "../../features/send_review/StepTwo"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  resetPage,
  selectSendReviewFormData,
  updateErrors,
} from "./sendReviewSlice"
import { skipToken } from "@reduxjs/toolkit/query"
import { selectCurrentUser } from "../../features/auth/authSlice"
import {
  selectGuestModal,
  selectLoginModal,
  selectRegistrationModal,
  setLoginModal,
  setRegistrationModal,
} from "../../features/modals/modalsSlice"
import { LoginModal } from "../../features/modals/LoginModal"
import { GuestModal } from "../../features/modals/GuestModal"
import { RegistrationModal } from "../../features/modals/RegistrationModal"
import {
  useCreateReviewMutation,
  useUploadImagesMutation,
} from "../../app/services/base/reviews"
import { addToast } from "../../features/toasts/toastsSlice"
import { useValidateInvitationOrderQuery } from "../../app/services/base/invitations"
import { uploadVimeoVideo } from "../../utils/videoUtils"
import { Review } from "../../models/base/review.interface"
import { useCreateUserMutation } from "../../app/services/base/users"
import { forceIsOpenTo } from "../../features/search_engine/searchEngineSlice"
import spinnerImage from "../../assets/images/coda-bianca.webp"
import { Helmet } from "react-helmet"
import { User } from "../../models/base/user.interface"

export const SendReview: React.FC = () => {
  const [createReview, { error: createReviewError }] = useCreateReviewMutation()
  const [createUser, { error: createUserError }] = useCreateUserMutation()
  const [uploadImages, { error: uploadError }] = useUploadImagesMutation()
  const [eshopDomain, setEshopDomain] = useState<string>("")
  const [forcedLoadingSpinner, setForcedLoadingSpinner] =
    useState<boolean>(false)
  const user = useAppSelector(selectCurrentUser)
  const tempUser = useAppSelector(selectCurrentUser)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const loginModal = useAppSelector(selectLoginModal)
  const registrationModal = useAppSelector(selectRegistrationModal)
  const guestModal = useAppSelector(selectGuestModal)
  const [searchParams, setSearchParams] = useSearchParams()
  const { eshopId, code } = useParams<{ eshopId: string; code: string }>()
  const eShopUrlParam = searchParams.get("e") || eshopId // ID | domain.com
  const isDomain = eShopUrlParam?.includes(".")
  const tempCode = code !== undefined ? code.replaceAll("_", "/") : undefined
  const invitationCode = searchParams.get("v") || tempCode // 628425/e6f7510dfc80024e2942aed9bac6323f

  if (!eShopUrlParam) {
    navigate("/")
  }
  const [step, setStep] = useState<number>(0)
  const [slide, setSlide] = useState<number>(0)

  const formData = useAppSelector(selectSendReviewFormData)

  const { data: shop } = useGetShopByIdQuery(
    eShopUrlParam && !isDomain ? eShopUrlParam : skipToken,
  )

  const { data: invitation } = useValidateInvitationOrderQuery(
    eShopUrlParam && invitationCode
      ? { e: eShopUrlParam, v: invitationCode }
      : skipToken,
  )

  const isProductReview = invitation && invitation.products ? true : false

  function getSendType() {
    if (invitation) {
      const st = searchParams.get("st") || "s"
      switch (st) {
        case "s":
          return "sms"
        case "w":
          return "whatsapp"
        case "e":
          return "email"
        default:
          return "email"
      }
    } else {
      return "email"
    }
  }

  function changeSlide(slideTo: "prev" | "next") {
    const stepIndicator = document.querySelector(
      ".step-indicator-container",
    ) as HTMLElement
    let scrollValue = stepIndicator?.offsetTop
    if (slideTo === "prev") {
      setSlide(slide - 1)
    } else if (slideTo === "next") {
      const nextSlide = slide + 1

      if (validateSlide()) {
        if ((isMobile && nextSlide === 3) || (!isMobile && nextSlide === 2)) {
          changeStep("next")
          scrollValue = 0
        } else if (!isMobile || nextSlide >= 1) {
          setSlide(nextSlide)
        }
      }
    }

    // Use requestAnimationFrame to ensure the layout is updated before scrolling
    requestAnimationFrame(() => {
      window.scrollTo({
        top: scrollValue,
        behavior: "smooth",
      })
    })
  }

  function changeStep(stepTo: "prev" | "next", forceStep?: number) {
    if (forceStep != undefined) {
      if (forceStep == 0) {
        setSlide(0)
      }
      setStep(forceStep)
      return
    }

    const nextStep = stepTo === "prev" ? step - 1 : step + 1

    if (
      stepTo === "next" &&
      ((isProductReview && nextStep > 2) || (!isProductReview && nextStep > 1))
    ) {
      if (user) {
        handleSubmit()
      } else {
        dispatch(setRegistrationModal(true))
      }
    } else {
      setStep(nextStep)
      // Use requestAnimationFrame to ensure the layout is updated before scrolling
      requestAnimationFrame(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      })
    }
  }

  function validateSlide() {
    const errors = {} as Record<string, string>

    const validateScore = (
      start: number,
      end?: number,
      filterName?: string,
    ) => {
      Object.keys(formData.shop.scores)
        .slice(start, end)
        .filter((name) => !filterName || name !== filterName)
        .forEach((name) => {
          if (formData.shop.scores[name] === 0) {
            errors[name] = "Questo campo è obbligatorio."
          }
        })
    }

    if (isMobile) {
      if (slide === 0 || slide === 1) {
        validateScore(0, slide === 0 ? 3 : undefined, "Assistenza clienti")
      } else if (slide === 2) {
        if (formData.shop.title.length < 3)
          errors.title = "Questo campo è obbligatorio."
        if (formData.shop.description.length < 10)
          errors.description = "Inserisci almeno 10 caratteri."
      }
    } else if (slide === 0 || slide === 1) {
      validateScore(0, undefined, "Assistenza clienti")
      if (slide === 1) {
        if (formData.shop.title.length < 3)
          errors.title = "Questo campo è obbligatorio."
        if (formData.shop.description.length < 10)
          errors.description = "Inserisci almeno 10 caratteri."
      }
    }

    dispatch(updateErrors(errors))
    return Object.keys(errors).length === 0
  }

  async function handleSubmit(tempUser = undefined as User | undefined) {
    try {
      setForcedLoadingSpinner(true)
      // Manage the videoUrl if present
      let videoUrl = formData.shop.videoUrl
      if (formData.shop.video) {
        let selectedVideo = formData.shop.video[0].file
        let domainName =
          (eShopUrlParam && isDomain) || !shop
            ? eShopUrlParam
            : shop?.eshopData.showcaseData.eshop_name

        let vimeoTitle = user
          ? `${user.firstname} recensisce ${domainName}`
          : `Recensione su ${domainName}`

        videoUrl = await uploadVimeoVideo(selectedVideo, vimeoTitle)
      }
      let body = {
        ...formData,
        shop: {
          ...formData.shop,
          videoUrl: videoUrl,
        },
      }

      let review: Review | undefined = undefined
      if (tempUser) {
        review = await createReview({
          e: shop ? shop.id : Number(eShopUrlParam)!,
          domain: eShopUrlParam && isDomain ? eShopUrlParam : undefined,
          v: invitationCode ? invitationCode : undefined,
          userId: tempUser.id,
          body: body,
          sendType: getSendType(),
        }).unwrap()
      } else if (user) {
        review = await createReview({
          e: shop ? shop.id : Number(eShopUrlParam)!,
          domain: eShopUrlParam && isDomain ? eShopUrlParam : undefined,
          v: invitationCode ? invitationCode : undefined,
          userId: user.id,
          body: body,
          sendType: getSendType(),
        }).unwrap()
      } else if (!user && invitation) {
        // Recensione come ospite
        const userName =
          invitation.customer_firstname || invitation.customer_lastname
        let newUser = await createUser({
          name: userName || "",
          email: invitation.customer_email,
          type: "guest",
        }).unwrap()
        review = await createReview({
          e: shop ? shop.id : Number(eShopUrlParam)!,
          domain: eShopUrlParam && isDomain ? eShopUrlParam : undefined,
          v: invitationCode ? invitationCode : undefined,
          userId: newUser.user_id,
          body: body,
          sendType: getSendType(),
        }).unwrap()
      }

      if (!review) throw new Error()

      if (formData.shop.images.length > 0) {
        const filepondImages = (window as any)?.pond
          .getFiles()
          .map((fileItem: any) => fileItem.file)

        const request = new FormData()
        filepondImages.forEach(async (image: any) => {
          request.append("review_id", review!.id.toString())
          request.append("is_product", "false")
          request.append("images[]", image, image.name)
        })
        const response = await uploadImages(request).unwrap()
      }

      if (tempUser) {
        navigate("/registrazione/ok")
        return
      }

      if (invitation) {
        if (invitation.coupon_id) {
          navigate(
            `/insert-review-ok?id=${review.id}&v=1&c=${invitation.coupon_id}&e=${eShopUrlParam}`,
          )
        } else {
          navigate(`/insert-review-ok?id=${review.id}&v=1&e=${eShopUrlParam}`)
        }
      } else {
        navigate(`/reviews-upload/${review.id}?e=${eShopUrlParam}`)
      }
      dispatch(resetPage())
    } catch (err: any) {
      dispatch(
        addToast({
          message:
            err.data.message == "Email not valid."
              ? "Ops! L'utente risulta essere già registrato."
              : "Ops! Impossibile creare la recensione al momento.",
          type: "danger",
        }),
      )
    }
  }

  const windowSize = useWindowSize()
  const isMobile = windowSize.width && windowSize.width <= 768

  useEffect(() => {
    if (!isMobile && slide >= 1) {
      setSlide(slide - 1)
    } else if (isMobile && slide === 1) {
      setSlide(slide + 1)
    }
  }, [isMobile])

  useEffect(() => {
    dispatch(forceIsOpenTo(false))
  }, [])

  useEffect(() => {
    setEshopDomain(shop?.domain || "")
    debugger
  }, [shop])

  useEffect(() => {
    dispatch(resetPage())
  }, [location])

  return (
    <>
      <Helmet>
        <title>{`Scrivi la tua recensione di ${
          shop?.domain || eShopUrlParam
        }`}</title>
        <meta
          name="description"
          content={`Valuta i servizi di ${
            shop?.domain || eShopUrlParam
          } dopo il tuo acquisto su questo negozio online`}
        />
        <meta name="robots" content="noindex,nofollow" />
        <link rel="canonical" type="" href={window.location.href} />
      </Helmet>
      <StepIndicators step={step} isProductReview={isProductReview} />
      <div className="container customContainer">
        <Form
          id="review-form"
          className="default-form myform lg-w-50 mx-auto"
          autoComplete="off"
          noValidate
        >
          <Carousel
            activeIndex={step}
            slide={false}
            controls={false}
            indicators={false}
            keyboard={false}
            wrap={false}
            touch={false}
          >
            <Carousel.Item>
              <StepOne
                isMobile={isMobile}
                slide={slide}
                shop={shop}
                changeSlide={changeSlide}
              />
            </Carousel.Item>
            {isProductReview && invitation && invitation.products && (
              <Carousel.Item>
                <StepTwo products={invitation.products} shop={shop} />
              </Carousel.Item>
            )}
            <Carousel.Item
              className="mx-auto"
              style={{ marginBottom: "114px", float: "none" }}
            >
              <StepThree
                shop={shop}
                changeStep={changeStep}
                products={invitation?.products}
              />
            </Carousel.Item>
          </Carousel>
          {step > 0 && (
            <div className="w-100" style={{ background: "#f7f7f7" }}>
              <div id="stepButtons" className="mx-auto">
                <div className="d-flex align-items-center justify-content-center mb-5 gap-1 mt-3">
                  <button
                    type="button"
                    className="btn btn-outline-gray background-white font-16 font-semi-bolder flex-grow-1 me-1 w-50"
                    style={{ height: "50px" }}
                    onClick={() => changeStep("prev")}
                  >
                    Precedente
                  </button>
                  <button
                    type="button"
                    className="btn btn-gradient-orange font-16 font-semi-bolder flex-grow-1 ms-1 w-50"
                    style={{ height: "50px" }}
                    onClick={() => changeStep("next")}
                  >
                    {isProductReview && step < 2 ? "Successivo" : "Pubblica"}
                  </button>
                </div>
              </div>
            </div>
          )}
        </Form>
      </div>
      {loginModal && <LoginModal invitationOrder={invitation} />}
      {registrationModal && (
        <RegistrationModal
          invitationOrder={invitation}
          afterConfirm={handleSubmit}
        />
      )}
      {guestModal && <GuestModal afterConfirm={handleSubmit} />}
      {forcedLoadingSpinner && (
        <div id="waitOverlay" className={"waitOverlay"}>
          <img
            alt="coda-bianca"
            src={spinnerImage}
            className="img-fluid"
            style={{ width: "200px" }}
          />
        </div>
      )}
    </>
  )
}
