import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { skipToken } from "@reduxjs/toolkit/query"
import {
  useGetProductDataQuery,
  useGetSearchProductsQuery,
  useGetProductsRelatedQuery,
} from "../../app/services/esaScraper/products"
import {
  capitalizeFirstLetter,
  formatNumber,
  removeHtmlTags,
} from "../../utils/stringUtils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  selectInfoModal,
  setInfoModal,
} from "../../features/modals/modalsSlice"
import { ReviewsCarouselSection } from "../../features/reviews_carousel/ReviewsCarouselSection"
import { HeadStickyBar } from "../../features/head_stickybar/HeadStickyBar"
import {
  useGetCouponsQuery,
  useGetCouponsUserHistoryQuery,
} from "../../app/services/base/coupons"
import { CouponBand } from "../../features/coupon_band/CouponBand"
import { useGetSearchReviewsQuery } from "../../app/services/esaScraper/reviews"
import { CardCarouselSection } from "../../features/card_carousel/CardCarouselSection"
import { ProductSchema } from "../../features/schemas/ProductSchema"
import { ProductPriceInfo } from "../../features/info_contents/ProductPriceInfo"
import { useGetShopsQuery } from "../../app/services/base/shops"
import { AppBreadcrumb } from "../../features/app_breadcrumb/AppBreadcrumb"
import { RatingStars } from "../../features/rating_stars/RatingStars"
import { InfoModal } from "../../features/modals/InfoModal"
import { selectCurrentUser } from "../../features/auth/authSlice"
import { calcTotalReviews } from "../../utils/shopUtils"
import { forceIsOpenTo } from "../../features/search_engine/searchEngineSlice"
import debounce from "lodash/debounce"
import { Helmet } from "react-helmet"

export const ProductDetails: React.FC = () => {
  const user = useAppSelector(selectCurrentUser)
  const dispatch = useAppDispatch()
  const productPriceModal = useAppSelector(selectInfoModal)
  const { eShopDomain, productName, linkDomainId } = useParams()
  const { eShop } = useGetShopsQuery(
    eShopDomain ? { domain: eShopDomain } : skipToken,
    {
      selectFromResult: ({ data }) => ({
        eShop: data?.find((shop) => shop.domain === eShopDomain),
      }),
    },
  )
  const { data: productData } = useGetProductDataQuery(
    linkDomainId ? { link_domain_id: linkDomainId } : skipToken,
  )
  const { data: productReviews } = useGetSearchReviewsQuery(
    productData && eShop && linkDomainId
      ? {
          esaDomainId: eShop.id,
          review_prod: true,
          filters: {
            enrichData: true,
            reviews: productData.reviews_ids,
            size: 10,
            esa_user_id: user ? user.id : undefined,
          },
          searchAfter: undefined,
        }
      : skipToken,
  )
  const { data: shopReviews } = useGetSearchReviewsQuery(
    eShop && (!productReviews || productReviews.hits.hits.length == 0)
      ? {
          esaDomainId: eShop.id,
          filters: { enrichData: true, size: 10 },
          searchAfter: undefined,
        }
      : skipToken,
  )

  const { data: productsRelated } = useGetProductsRelatedQuery(
    linkDomainId ? linkDomainId : skipToken,
  )

  const { data: otherProducts } = useGetSearchProductsQuery(
    eShop
      ? {
          filters: {
            eShopDomain: { name: "", label: "", value: eShop.domain },
          },
          searchAfter: undefined,
        }
      : skipToken,
  )

  const { data: couponWithShop } = useGetCouponsQuery(
    eShop ? { eshop_id: eShop.id, page: 1, perPage: 1 } : skipToken,
  )

  const { data: unlockedCoupons } = useGetCouponsUserHistoryQuery(
    user
      ? {
          userId: user.id,
        }
      : skipToken,
  )

  const [showStickyBar, setShowStickyBar] = useState<boolean>(false)

  useEffect(() => {
    const debouncedHandleScroll = debounce(() => {
      const scroll = window.scrollY

      if (scroll >= 150) {
        setShowStickyBar(true)
      } else if (scroll < 150) {
        setShowStickyBar(false)
      }
    }, 15)

    dispatch(forceIsOpenTo(false))

    window.addEventListener("scroll", debouncedHandleScroll)

    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll)
    }
  }, [])

  if (!productData || !eShop) {
    return <></>
  }

  return (
    <>
      <Helmet>
        <title>{productData.product_name.replace(/"/g, "'")}</title>
        <meta
          name="title"
          content={productData.product_name.replace(/"/g, "'")}
        />
        <meta
          name="description"
          content={productData.product_description.replace(/"/g, "'")}
        />
        <link rel="canonical" type="" href={window.location.href} />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview: large"
        />
      </Helmet>
      {showStickyBar && (
        <HeadStickyBar
          title={capitalizeFirstLetter(productData.product_name)}
          rating={productData.product_rating}
          shop={eShop}
          thumbImage={
            productData.cdn_image_url
              ? productData.cdn_image_url
              : productData.product_image
          }
          ctaOne="Acquista"
          urlCtaOne={productData.product_url}
          ctaTwo="Vai alla vetrina"
          urlCtaTwo={`/eshop/${eShop.domain}`}
          toSiteCta="#"
        />
      )}
      <section id="breadcrumbs">
        <AppBreadcrumb
          routes={[
            { name: "Home", slug: "/" },
            { name: eShop.domain, slug: `/eshop/${eShop.domain}` },
            { name: "Prodotti", slug: `/prodotti-eshop/${eShop.domain}` },
            { name: "", slug: "" },
          ]}
        />
      </section>
      <section id="product">
        <div className="container">
          <div className="row">
            <div className="my-3 col-12 col-md-5 col-lg-5">
              <div className="card rounded border d-flex w-auto height-420 p-2">
                <img
                  src={productData.product_image}
                  className="p-2 img-fluid w-auto m-auto max-height-420 headBarTrigger"
                  alt={`${productData.product_name} venduto da ${eShop.domain}`}
                />
              </div>
            </div>
            <div className="my-3 col-12 col-md-6 col-lg-7">
              <h1 className="bolder-dark-title font-33 my-3 my-md-1">
                {capitalizeFirstLetter(productData.product_name)}
              </h1>

              {eShop &&
                (!productReviews || productReviews.hits.hits.length == 0) && (
                  <div className="d-block my-2">
                    <Link
                      to={`/eshop/${eShop.domain}`}
                      className="simple-orange-link font-16"
                      title={
                        eShop.eshopData.showcaseData.eshop_name
                          ? eShop.eshopData.showcaseData.eshop_name
                          : eShop.domain
                      }
                    >
                      {eShop.eshopData.showcaseData.eshop_name
                        ? eShop.eshopData.showcaseData.eshop_name
                        : eShop.domain}
                    </Link>
                  </div>
                )}

              <div className="row align-items-center">
                <div className="col-auto col-xl-5">
                  <RatingStars
                    rating={
                      productData.product_rating > 0
                        ? productData.product_rating
                        : eShop.rating || 0
                    }
                    showAverageRating
                    size="small"
                    nReviewsCompact={
                      productData.reviews_ids.length > 0
                        ? productData.reviews_ids.length
                        : calcTotalReviews(eShop)
                    }
                  />
                </div>
              </div>

              {eShop &&
                productReviews &&
                productReviews.hits.hits.length > 0 && (
                  <div className="d-block my-2">
                    <Link
                      to={`/eshop/${eShop.domain}`}
                      className="simple-orange-link font-16"
                      title={eShop.domain}
                    >
                      {eShop.eshopData.showcaseData.eshop_name
                        ? eShop.eshopData.showcaseData.eshop_name
                        : eShop.domain}
                    </Link>
                  </div>
                )}

              <div className="d-block py-2">
                {productData.product_base_price &&
                productData.product_base_price > 0 &&
                productData.product_discount &&
                productData.product_discount > 0 ? (
                  <>
                    <span className="gray-text font-23 font-light">
                      <del>
                        {formatNumber(productData.product_base_price, 2)} €{" "}
                      </del>
                    </span>
                    <span className="badge badge-orange mx-3">
                      -{productData.product_discount}%
                    </span>
                  </>
                ) : (
                  ""
                )}
                <span className="dark-color font-bolder font-23">
                  {formatNumber(productData.product_price, 2)} €
                </span>
                <div className="d-block">
                  <span className="d-flex align-items-center gray-color font-16 font-semi-bold">
                    L'ultimo prezzo più basso:{" "}
                    {formatNumber(
                      productData.lowest_price_record
                        ? productData.lowest_price_record.price
                        : productData.product_price,
                      2,
                    )}
                    €
                    <div
                      className="ms-3 d-inline-flex align-items-center justify-content-center cursor-pointer"
                      style={{
                        border: "1px solid #1e3d87",
                        borderRadius: "30px",
                        gap: "1px",
                        padding: "5px 15px",
                      }}
                      onClick={() =>
                        dispatch(
                          setInfoModal({
                            title: "Descrizione del prezzo",
                            body: <ProductPriceInfo />,
                          }),
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={["fas", "info"]}
                        style={{ fontSize: "10px", color: "#1e3d87" }}
                      />
                    </div>
                  </span>
                </div>
              </div>
              <div className="d-block">
                <span className="fw-semibold dark-color"> Descrizione </span>
                <p
                  id="productDescription"
                  className="gray-text orange-scrollbar mt-2"
                >
                  {removeHtmlTags(productData.product_description)}
                </p>
              </div>
              <div className="d-block margin-t24">
                <div className="row align-items-center">
                  <div className="col-12 col-md-6 text-center">
                    <Link
                      to={productData.product_url}
                      title={`Acquista ${productData.product_name} su ${eShop.domain}`}
                      target="_blank"
                      className="btn btn-gradient-orange btn-animation height-50 d-flex align-items-center justify-content-center py-3 px-3 w-100"
                    >
                      Acquista
                    </Link>
                  </div>
                  <div className="col-12 col-md-6 text-center my-3">
                    <Link
                      to={`/eshop/${eShop.domain}`}
                      title={`Tutte le recensioni di ${eShop.domain}`}
                      className="simple-orange-link font-16 fw-semibold"
                    >
                      Vai alla vetrina
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {couponWithShop && couponWithShop.items.length > 0 && (
        <CouponBand
          couponWithShop={couponWithShop.items[0]}
          isUnlocked={
            unlockedCoupons
              ? unlockedCoupons?.purchases.some(
                  (purchase) =>
                    purchase.id == couponWithShop.items[0].coupon.id,
                )
              : false
          }
        />
      )}
      {productReviews &&
      productReviews.hits &&
      productReviews.hits.hits.length > 0 ? (
        <ReviewsCarouselSection
          title="Ultime recensioni"
          customReviews={productReviews.hits.hits}
          rating={eShop.rating}
          nReviews={productData.reviews_ids.length}
        />
      ) : shopReviews &&
        shopReviews.hits &&
        shopReviews.hits.hits.length > 0 ? (
        <ReviewsCarouselSection
          title="Ultime recensioni"
          customTitle={`dei clienti di ${eShop.domain}`}
          customReviews={shopReviews.hits.hits}
          rating={eShop.rating}
          nReviews={calcTotalReviews(eShop)}
        />
      ) : (
        <></>
      )}
      {eShop && productData && productsRelated && (
        <CardCarouselSection
          id={"related"}
          title="Prodotti simili"
          products={productsRelated.hits.hits}
          showMoreTitle="Vedi tutti"
          typeOfProducts="RelatedProducts"
          shop={eShop}
        />
      )}
      {eShop && productData && otherProducts && (
        <CardCarouselSection
          id={"site"}
          title="Altri prodotti"
          products={otherProducts.hits.hits}
          showMoreTitle="Vedi tutti"
          showMoreLink={`/prodotti-eshop/${eShop.domain}`}
          typeOfProducts="EShopProducts"
          shop={eShop}
        />
      )}
      <ProductSchema
        product={{
          domain: eShop.domain,
          domain_id: productData.esa_domain_id,
          link_domain_id: 0,
          url: productData.product_url,
          image_url: productData.product_image,
          cdn_image_url: productData.cdn_image_url,
          esa_id: 0,
          product_name: productData.product_name,
          product_name_raw: productData.product_name,
          product_kws: productData.product_kws,
          keywords: productData.product_kws,
          name_suggest: {
            input: [],
          },
          price: productData.product_base_price,
          low_price: productData.product_low_price,
          high_price: productData.product_high_price,
          currency: productData.product_price_currency,
          description: productData.product_description,
          adult_flag: productData.adult_flag ? true : false,
          score: productData.product_rating,
          reviews: productData.reviews_ids.length,
          reviews_30: 0,
          reviews_cert: 0,
          product_details_enabled: false,
          pagerank_internal: 0,
          plan: 0,
          has_affiliation: false,
          esa_category_id: [],
          esa_subcategory_id: [],
          last_update: "",
        }}
        reviews={
          productReviews
            ? productReviews.hits.hits.map((hit) => hit._source)
            : undefined
        }
        isAffiliated={eShop.eshopData.structured_data.urlAff ? true : false}
      />
      {productPriceModal && <InfoModal />}
    </>
  )
}
